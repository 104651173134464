import pino from 'pino';

const log = pino({
  name: process.env.PROCESS_NAME || 'enterprise',
  level: process.env.NODE_ENV === 'production' ? 'info' : 'debug',
  ...(process.env.NODE_ENV !== 'production' && {
    transport: {
      target: 'pino-pretty',
    },
  }),
});
export default log;
